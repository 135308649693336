import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", {
    ref: "canvasEl",
    height: _ctx.canvasHeight,
    width: _ctx.canvasWidth,
    style: {"height":"100%","width":"100%"}
  }, null, 8, _hoisted_1))
}